import React, { ChangeEvent, FormEvent, useState } from "react";
import { Map, Marker, TileLayer } from 'react-leaflet';
import { LeafletMouseEvent } from "leaflet";
import { useHistory } from "react-router-dom";

import { FiPlus } from "react-icons/fi";

import Sidebar from "../components/Sidebar";
import mapIcon from "../utils/mapIcon";
import api from "../services/api";

import '../styles/pages/create-mora10.css';

export default function CreateMora10() {
  const history =useHistory();

  const [position, setPosition] = useState({ latitude: 0, longitude: 0 });

  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [instructions, setInstructions] = useState("");
  const [opening_hours, setOpeningHours] = useState("");
  const [open_on_weekends, setOpenOnWeekends] = useState(true);
  const [images, setImages] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  
  function handleMapClick(event: LeafletMouseEvent) {
    const { lat, lng } = event.latlng;

    setPosition({
      latitude: lat,
      longitude: lng,
    })
  }
  
  function handleSelectImages(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const selectedImages = Array.from(event.target.files);
 
    setImages(selectedImages);

    const selectedImagesPreview = selectedImages.map(image => {
      return URL.createObjectURL(image);
    });
    
    setPreviewImages(selectedImagesPreview);
  }  

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const { latitude, longitude } = position;

    const data = new FormData();

    data.append("name", name);
    data.append("about", about);
    data.append("latitude", String(latitude));
    data.append("longitude", String(longitude));
    data.append("instructions", instructions);
    data.append("opening_hours", opening_hours);
    data.append("open_on_weekends", String(open_on_weekends));
    data.append("images", name);

    images.forEach(image => {
      data.append("images", image);
    })

    await api.post("mora10s", data);

    alert("Cadastro realizado com Sucesso!");

    history.push("/app");
  }

  return (
    <div id="page-create-mora10">
      <Sidebar />

      <main>
        <form onSubmit={handleSubmit} className="create-mora10-form">
          <fieldset>
            <legend>Imóvel</legend>

            <Map 
              center={[-26.777337,-51.011618]} 
              style={{ width: '100%', height: 280 }}
              zoom={15}
              onclick={handleMapClick}
            >
              <TileLayer 
                url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
              />

              { position.latitude !== 0 && (
                <Marker
                  interactive={false} 
                  icon={mapIcon} 
                  position={[
                    position.latitude,
                    position.longitude
                  ]} 
                /> 
              )}
            </Map>

            <div className="input-block">
              <label htmlFor="name">Nome</label>
              <input 
                id="name" 
                value={name} 
                onChange={event => setName(event.target.value)} 
              />
            </div>

            <div className="input-block">
              <label htmlFor="about">Descrição <span>Máximo de 300 caracteres</span></label>
              <textarea 
                id="name" 
                maxLength={300} 
                value={about} 
                onChange={event => setAbout(event.target.value)} 
              />
            </div>

            <div className="input-block">
              <label htmlFor="images">Fotos</label>

              <div className="images-container">
                {previewImages.map(image => {
                  return (
                    <img key={image} src={image} alt={name} />
                  )
                })}
                
                <label htmlFor="image[]" className="new-image">
                  <FiPlus size={24} color="#69FF47" />
                </label>

              </div>     

              <input multiple onChange={handleSelectImages}  type="file" id="image[]" />         
            </div>
          </fieldset>

          <fieldset>
            <legend>Especificações</legend>
            <div className="columns">
            <div className="input-value">
              <label htmlFor="instructions">Valor 10 Dias</label>
              R$<input 
                type="number"
                step="0.01"
                min="0.01"
                id="instructions" 
                value={instructions} 
                onChange={event => setInstructions(event.target.value)} 
              />
            </div>
            <div className="input-value">
              <label htmlFor="opening_hours">Valor 1 Mês</label>
              R$<input 
                type="number"
                step="0.01"
                min="0.01"
                id="opening_hours" 
                value={opening_hours} 
                onChange={event => setOpeningHours(event.target.value)} 
              />
            </div>
            </div>

            <div className="input-block">
              <label htmlFor="open_on_weekends">É Mora10 ?</label>

              <div className="button-select">
                <button 
                  type="button" 
                  className={open_on_weekends ? "active" : ""} 
                  onClick={() => setOpenOnWeekends(true)}
                >
                  Sim
                </button>
                <button 
                  type="button"
                  className={!open_on_weekends ? "active" : ""} 
                  onClick={() => setOpenOnWeekends(false)}
                >
                  Não
                </button>
              </div>
            </div>
          </fieldset>

          <button className="confirm-button" type="submit">
            Cadastrar
          </button>
        </form>
      </main>
    </div>
  );
}

// return `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`;
