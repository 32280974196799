import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useAuth } from "../context/AuthContext";

import '../styles/pages/login.css';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();      
      await signIn({ email, password });
    },  
    [email, password]
  );



  return (
    <div id="page-login">
      <Sidebar />
      <main>  
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="login-details">
            <div className="logo_img"></div>  
            <div className="input-block">
              <label htmlFor="email">E-mail:</label>
              <input 
                id="email"
                type="text" 
                placeholder="e-mail"
                onChange={(event) => setEmail(event.target.value)} 
              />
              <label htmlFor="email">Senha:</label>
              <input 
                id="password" 
                type="password" 
                placeholder="$enh@" 
                onChange={(event) => setPassword(event.target.value)}
              />
              </div>
            <button className="confirm-button" type="submit">
              Entrar
            </button>
            <Link className="add-button" to="/cadastro">
              Cadastrar
            </Link>
            <div className="forgot">
              <Link to="/cadastro">
                Esqueceu Senha
              </Link>
              <Link className="pular" to="/app">
                Pular
              </Link>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Login;