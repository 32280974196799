import React, { FormEvent, useState } from "react";
import Sidebar from "../components/Sidebar";

import '../styles/pages/cadastro.css';

import api from "../services/api";

const Cadastro: React.FC = () => {
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
    
  async function handleSubmit(event: FormEvent) {
    event.preventDefault(); 
    const data = {
      name:nome,
      familyName:sobrenome,
      email:email,
      password: password, 
      roles:"619b8772-9e58-44d4-8fc8-daf8b07389eb"} 
    
    const response = await api.post('/users', data);

    if(response.status === 201) {
      window.location.href='/login'
    }else{
      alert('Erro ao cadastrar usuário!');
    }
  }  

  return (
    <div id="page-cadastro">
      <Sidebar />
      <main>  
        <form className="cadastro-form" onSubmit={handleSubmit}>
          <div className="cadastro-details">
            <label className="cadastro-label">Cadastre-se</label>  
            <div className="input-block">
              <input 
                id="nome"
                type="text" 
                placeholder="Nome"
                value={nome}
                onChange={(event) => setNome(event.target.value)} 
              />
              <input 
                id="sobrenome" 
                type="text" 
                placeholder="Sobrenome"
                value={sobrenome} 
                onChange={(event) => setSobrenome(event.target.value)}
              />
              <input 
                id="email"
                type="email" 
                placeholder="e-mail"
                value={email}
                onChange={(event) => setEmail(event.target.value)} 
              />
              <input 
                id="password" 
                type="password" 
                placeholder="$enh@"
                value={password} 
                onChange={(event) => setPassword(event.target.value)}
              />
              {/* <input 
                id="confirmar-password" 
                type="password" 
                placeholder="Conf!rm@r $enh@" 
              /> */}
              </div>
            <button className="add-button" onClick={handleSubmit}>
              Cadastrar
            </button>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Cadastro;