import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { SiCashapp } from "react-icons/si";
import { RiHomeSmileLine } from "react-icons/ri";
import { Map, Marker, TileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";


import Sidebar from "../components/Sidebar";
import mapIcon from "../utils/mapIcon";
import api from "../services/api";

import '../styles/pages/mora10.css';

interface Mora10 {
  latitude: number;
  longitude: number;
  name: string;
  about: string;
  instructions: string;
  opening_hours: string;
  open_on_weekends: string;
  images: Array<{
    id: number;
    url: string;
  }>;
}

interface Mora10Params {
  id: string;
}

export default function Mora10() {
  const params = useParams<Mora10Params>();
  const [mora10, setMora10] = useState<Mora10>();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  
  useEffect(() => {
    api.get(`mora10s/${params.id}`).then(response => {
      setMora10(response.data);
    });
  }, [params.id]);

  if (!mora10) {
    return <p>Carregando...</p>;
  }

  return ( 
    <div id="page-mora10">
      <Sidebar />
      <main>
        <div className="mora10-details">
          <img src={mora10.images[activeImageIndex].url} alt={mora10.name} />

          <div className="images">
            {mora10.images.map((image, index) => {
              return (
                <button 
                  key={image.id} 
                  className={activeImageIndex === index ? "active" : ""} 
                  type="button"
                  onClick={() => {
                    setActiveImageIndex(index)
                  }}
                >
                  <img src={image.url} alt={mora10.name} />
                </button>
              );
            })}
            
          </div>
          
          <div className="mora10-details-content">
            <h1>{mora10.name}</h1>
            <p>{mora10.about}</p>

            <div className="map-container">
              <Map 
                center={[mora10.latitude,mora10.longitude]} 
                zoom={15} 
                style={{ width: '100%', height: 280 }}
                dragging={false}
                touchZoom={false}
                zoomControl={false}
                scrollWheelZoom={false}
                doubleClickZoom={false}
              >
                <TileLayer 
                  url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                />
                <Marker interactive={false} icon={mapIcon} position={[mora10.latitude,mora10.longitude]} />
              </Map>

              <footer>
                <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/dir/?api=1&destination=${mora10.latitude},${mora10.longitude}`}>Ver rotas no Google Maps</a>
              </footer>
            </div>

            <hr />

            <h2>Especificações</h2>
            
          

            <div className="detalhes">
              <div className="linha1">
                <div className="linha1coluna1">
                  <SiCashapp size={32} color="#69ff47" />
                  10 Dias <br/>
                  R$ {mora10.instructions} <br/>
                </div>
                <div className="linha1coluna2">
                  <SiCashapp size={32} color="#349CD7" />
                  1 Mês <br/>
                  R$ {mora10.opening_hours}
                </div>
              </div>
              { mora10.open_on_weekends ? (
                <div className="linha2 Mora10">
                  <FiCheck size={32} color="#753BBD" />
                  <p>É Mora10</p>
                </div>
              ) : (
                <div className="linha2 Convencional">
                  <RiHomeSmileLine size={32} color="#69ff47" />  
                  <p>Moradia Convencional</p>
                </div>
              )}
            </div>
              { <button
                    type="button"
                    className="contact-button"
                    /* onClick={()=> `whatsapp://send?text=Olá! tenho interesse no imóvel _*${mora10?.name}*_!&phone=5541996887370`} */
                  >
                    <FaWhatsapp size={35} color="#349CD7" />
                      Entrar em contato  
                </button>
              }
          </div>
        </div>
      </main>
    </div>
  )
}