import React from "react";
import { Route, Switch } from "react-router-dom";

import Landing from "../pages/Landing";
import Mora10Map from "../pages/Mora10Map";
import Mora10 from "../pages/Mora10";
import CreateMora10 from "../pages/CreateMora10";
import Login from "../pages/Login";
import Cadastro from "../pages/Cadastro";
import PrivateRoutes from "./PrivateRoutes";

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route path="/app" component={Mora10Map} />
      <Route path="/login" component={Login} />
      <Route path="/cadastro" component={Cadastro} />
      <PrivateRoutes 
        path="/mora10s/create"
        exact component={CreateMora10}
        role="ROLE_ADMIN" />
      <Route path="/mora10s/:id" component={Mora10} />
    </Switch>
  );
}

export default Routes;
