import React from "react";
import { AuthProvider } from "./context/AuthContext";

import "./styles/global.css";
import "leaflet/dist/leaflet.css";

import Routes from "./routes/routes";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
