import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";

interface AuthContextState {
  token: TokenState;
  signIn({email, password}: UserData): Promise<void>;
  userLogged(): boolean;
}

interface UserData {
  email: string;
  password: string;
}

interface TokenState {
  token: string;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<TokenState>(() => {
    const token = localStorage.getItem('@PermissionM10:token');
    
    if(token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
    
      return { token }
    }
    
    return {} as TokenState;
  });
  
  const signIn = useCallback(async ({ email, password }: UserData) => {
    const response = await api.post("/sessions", {
      email,
      password,
    });

    if(response.status === 200) {
      window.location.href='/app'
    }else{
      alert('Usuário ou Senha Incorreto!');
    }

    const { token } = response.data;

    setToken(token);

    localStorage.setItem('@PermissionM10:token', token);
    api.defaults.headers.authorization = `Bearer ${token}`;
  }, []);

  const userLogged = useCallback(() => {
    const token = localStorage.getItem('@PermissionM10:token');
    if(token) {
      return true;
    }
    return false;
  }, []);

  return (
    <AuthContext.Provider value={{ token, signIn, userLogged }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextState {
  const context = useContext(AuthContext);
  return context;
} 

export { AuthProvider, useAuth }; 