import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiArrowRight, FiPlus, FiUserCheck } from "react-icons/fi";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";

import moraLogo from "../images/mora-logo.svg";
import mapIcon from "../utils/mapIcon";
import api from "../services/api";

import "../styles/pages/mora10-map.css";
import PermissionComponent from "../components/PermissionComponent";

interface Mora10 {
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  instructions: string;
  opening_hours: string;
}

function Mora10Map() {
  const [mora10s, setMora10s] = useState<Mora10[]>([]);

  useEffect(() => {
    api.get("mora10s").then(response => {
      setMora10s(response.data);
    });
  }, []);
  
  
  return (
    <div id="page-map">
      <aside>
        <header>
          <Link to="/">
            <img src={moraLogo} alt="Mora10 Logo"/>
          </Link>

          <h2>Escolha sua Mora10 no mapa</h2>
          <p>Nós descomplicamos tudo pra você :)</p>
        </header>

        <footer>
          <strong>Já estamos em:</strong>
          <span>Santa Catarina</span>
        </footer>
      </aside>

      <Map 
        center={[-26.777337,-51.011618]}
        zoom={16.25}
        style={{ width: '100%', height: '100%' }}
      >
        <TileLayer url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`} />
        {/*<TileLayer 
          url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        />*/}

        {mora10s.map(mora10 => {
          return (
            <Marker
              key={mora10.id}
              icon={mapIcon} 
              position={[mora10.latitude, mora10.longitude]}           
            >
              <Popup closeButton={false} minWidth={240} maxWidth={240} className="map-popup">
                <h1>{mora10.name}<br/>
                  <p>R$ {mora10.instructions} - {mora10.opening_hours}</p>
                </h1>
                
                
                <Link to={`/mora10s/${mora10.id}`}>
                  <FiArrowRight size={20} color="#FFF" />
                </Link>
              </Popup>
            </Marker> 
          )
        })}         
      </Map>
        <Link to="/login" className="login">
          <FiUserCheck size={32} color="#FFF" />
        </Link>
      {/* <PermissionComponent role="ROLE_ADMIN" >
        <Link to="/mora10s/create" className="create-mora10">
          <FiPlus size={32} color="#FFF" />
        </Link>
      </PermissionComponent> */}
    </div>
  )
}

export default Mora10Map;