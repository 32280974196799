import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

import "../styles/pages/landing.css";

function Landing() {
  return (
    <div id="page-landing">
      <div className="content-wrapper">
        
        <main>
          <h1>Busque a sua Moradia!</h1>
          <p>Escolha que nós descomplicamos pra você :)</p>
        </main>

        <div className="location">
          <strong>Já estamos em:</strong>
          <span>Santa Catarina</span>
        </div>

        <Link to="/app" className="enter-app">
          Descomplique 
          <FiArrowRight size={26} color="#69FF47" />
        </Link> 
      </div>
    </div>
  );
}

export default Landing;